import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import About1 from "./AboutImages/About1.JPG";
import About2 from "./AboutImages/About2.JPG";
import About3 from "./AboutImages/About3.JPG";
import About4 from "./AboutImages/About4.jpg";
import About5 from "./AboutImages/About5.jpg";
import About6 from "./AboutImages/About6.jpg";
import About7 from "./AboutImages/About7.jpg";
import About8 from "./AboutImages/About8.jpg";
import About9 from "./AboutImages/About9.jpg";

const PastWorks = () => {
  return (
    <div className="pw-container">
      <div className="pw-details">
        <div className="pw-section">
          <div className="h1-pw">
            <h1>Past Works</h1>
          </div>
          <h2>South Africa</h2>
          <div className="pw-text">
            <h3>Fall 2022</h3>
            <p>
              In the fall semester of the 2022-2023 academic year, Krystal
              Lacayo had the opportunity to study abroad under the Honors Cape
              Town program at UNC. During her 4 month stay in South Africa,
              Krystal traveled the Eastern, Southern, and Western Capes learning
              more in-depth about the apartheid and post apartheid state. She
              studied at the University of Cape Town, taking classes on South
              African Literature and Colonial Structures. In addition, she
              interned at Development Action Group, an NGO serving those in
              informal settlements. Krystal worked within the Communications
              department where she aided in projects such as podcasts, formal
              write ups, blogs, and social media posts that disseminated
              research information regarding informal settlement aid, affordable
              housing options, urban planning, and community development
              initiatives.
            </p>
            <p>
              During her time abroad, Krystal came to understand the extreme
              extent of racism and colonial structures. Not only witnessing
              their extent in the professional world but also in her personal
              life, Krystal attributes the program to expanding her knowledge
              and wanting to influence a better world for all.
            </p>
            <div className="carousel-container-pw">
              <Carousel
                autoPlay
                interval={3000}
                infiniteLoop
                useKeyboardArrows
                dynamicHeight
                statusFormatter={(current, total) => `${current} of ${total}`}
              >
                <div>
                  <img src={About1} alt="" />
                </div>
                <div>
                  <img src={About2} alt="" />
                </div>
                <div>
                  <img src={About3} alt="" />
                </div>
                <div>
                  <img src={About4} alt="" />
                </div>
                <div>
                  <img src={About5} alt="" />
                </div>
                <div>
                  <img src={About6} alt="" />
                </div>
                <div>
                  <img src={About7} alt="" />
                </div>
                <div>
                  <img src={About8} alt="" />
                </div>
                <div>
                  <img src={About9} alt="" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="pw-section">
          <h2>GEN-ZERO: The Black Youth Association, Inc.</h2>
          <div className="pw-text">
            <p>
              During the 2020 COVID-19 pandemic, the nation saw the intentional
              negligence of police and their relationship to Black individuals
              and communities with the Black Lives Matter protests. Seeing those
              on the frontlines desperately advocating for all Black lives,
              Krystal founded her nonprofit during her senior year in high
              school named “GEN-ZERO: The Black Youth Association, Inc.” The
              purpose of GEN-ZERO (shortened) was to create a capacity building
              space for Black youth to converse, do community outreach, and
              experience cultural enrichment (known as “The 3 C’s”). Her
              approach included virtual interviews with other Black young
              leaders in the form of a podcast, virtual game nights, and
              providing essential items to those living in Tent City, Charlotte.
              Although lasting for around 10 months formally, Krystal’s first
              independent project dedicated to her community set her up to serve
              her community for life. Please visit{" "}
              <a
                href="https://sites.google.com/genzeronow.org/gen-zero/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                genzeronow.org
              </a>{" "}
              to see the archived work of the organization.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastWorks;
