import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>© 2023 Krystal Lacayo. All rights reserved.</p>
            {/* You can add more content here as needed */}
        </footer>
    );
};

export default Footer;