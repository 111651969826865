import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import CurrentWorksPage from "./pages/CurrentWorksPage";
import PastWorksPage from "./pages/PastWorksPage";
import ProjectsPage from "./pages/ProjectsPage";
import ContactPage from "./pages/ContactPage";
import "./styles.css"; // Your CSS styles
import logo from "./KL Logo.png";
import Footer from "./Footer";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null); // This ref will be attached to the dropdown

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <header>
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        {menuOpen && (
          <nav
            className={`nav-links ${menuOpen ? "active" : ""}`}
            ref={dropdownRef}
          >
            <Link className="link" to="/" onClick={() => setMenuOpen(false)}>
              Home
            </Link>

            <Link
              className="link"
              to="/about"
              onClick={() => setMenuOpen(false)}
            >
              About
            </Link>

            <Link
              className="link"
              to="/current-works"
              onClick={() => setMenuOpen(false)}
            >
              Current Works
            </Link>

            <Link
              className="link"
              to="/past-works"
              onClick={() => setMenuOpen(false)}
            >
              Past Works
            </Link>

            <Link
              className="link"
              to="/projects"
              onClick={() => setMenuOpen(false)}
            >
              Academic Projects
            </Link>

            <Link
              className="link"
              to="/contact"
              onClick={() => setMenuOpen(false)}
            >
              Contact
            </Link>
          </nav>
        )}
        <nav>
          <ul>
            <li>
              <Link className="link" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="link" to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className="link" to="/current-works">
                Current Works
              </Link>
            </li>
            <li>
              <Link className="link" to="/past-works">
                Past Works
              </Link>
            </li>
            <li>
              <Link className="link" to="/projects">
                Academic Projects
              </Link>
            </li>
            <li>
              <Link className="link" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/current-works" element={<CurrentWorksPage />} />
          <Route path="/past-works" element={<PastWorksPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </main>

      <Footer />
    </Router>
  );
}

export default App;
