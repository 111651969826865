import React from "react";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-details">
        <div className="about-section">
          <div className="h1-about">
            <h1>Her Story</h1>
          </div>
          <div className="about-text">
            <p style={{ marginBottom: "3em" }}>
              Krystal Lacayo is a 3rd year undergraduate student at the
              University of North Carolina at Chapel Hill. She is majoring in
              Environmental Studies on the Sustainability track and is pursuing
              a minor in Urban Planning. Krystal is increasingly interested in
              how the 3 E’s of sustainability (Economic, Equity, Environmental)
              can be actualized in philosophy that guides policies in areas such
              as housing, transportation methods, community development and
              rehabilitation, and social and economic equity building. She aims
              to complete her undergraduate degree and minor to work in land and
              community development in the private and public sectors.
            </p>
            <h2>Her Work - “The Lack of, is the Opportunity For”</h2>
            <p style={{ marginBottom: "3em" }}>
              This quote coined by Krystal is an everyday reminder to see chance
              in the face of challenge. As Krystal works to create change, she
              listens and looks for gaps that hinder progress. Finding these
              gaps, she analyzes the problem, researches and collaborates with
              other professionals, and identifies long and short term solutions.
            </p>
            <h2>Her Community - “Lift While You Climb”</h2>
            <p style={{ marginBottom: "3em" }}>
              This quote, spoken to her by her father, resembles how she moves
              throughout life. As she continues to reach new heights, it is
              important to her that progress and success is not only seen in her
              life, but also in her community’s.
            </p>
            <p>
              Her love for her community comes from her family, with members
              from the American South, Honduras (Garifuna), and Gambia. Outside
              of work, Krystal enjoys painting, poetry, running, and watching
              early 2000s movies and shows. She has a love for many things
              including marine science, astronomy, and the entertainment
              industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
