import React from "react";
import photo from "../krys-photo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const HomePage = () => {
  return (
    <div className="home">
      <div className="home-container">
        <div className="personal-details">
          <img src={photo} alt="Personal" className="personal-photo" />
          <div className="quote-section">
            <p className="quote">“The Lack of, is the Opportunity For”</p>
            <p
              className="description"
              style={{ textAlign: "center", marginTop: 0, marginBottom: "3em" }}
            >
              <i> - Krystal Lacayo</i>
            </p>
            <p
              className="description"
              style={{ marginBottom: "3em", marginLeft: 0 }}
            >
              <i>
                A dedicated and curious young lady who listens to and
                understands the layered needs of her community and sets out to
                be a changemaker in all capacities.
              </i>
            </p>
            <div className="center">
              <a
                href="https://www.linkedin.com/in/krystal-lacayo/"
                className="linkedin-button"
              >
                <FontAwesomeIcon icon={faLinkedin} className="linkedin-icon" />
                <u>Connect on LinkedIn</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
