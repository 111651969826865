import React from "react";
import { useForm, ValidationError } from "@formspree/react";

function ContactPage() {
  const [state, handleSubmit] = useForm("xpzgqvjb");
  if (state.succeeded) {
    return (
      <div className="form-container">
        <p>Thank you for your message, I will be in touch shortly!</p>
        <p>
          <i>- Krystal Lacayo</i>
        </p>
      </div>
    );
  }
  return (
    <div className="form-container">
      <div className="h1-contact">
        <h1 style={{ textAlign: "center" }}>Get in Touch</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Full Name</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
          style={{ color: "red" }}
        />

        <label htmlFor="message">Message</label>
        <textarea
          style={{ height: 180, marginBottom: 65 }}
          id="message"
          name="message"
          required
        ></textarea>
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
          style={{ color: "red" }}
        />

        <input
          type="hidden"
          id="g-recaptcha-response"
          name="g-recaptcha-response"
        />

        <button
          className="contact-button"
          type="submit"
          disabled={state.submitting}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default ContactPage;
