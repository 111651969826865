import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import SDLF1 from "./SDLF/SDLF1.JPG";
import SDLF2 from "./SDLF/SDLF2.JPG";
import SDLF3 from "./SDLF/SDLF3.JPG";
import SDLF4 from "./SDLF/SDLF4.JPG";
import SDLF5 from "./SDLF/SDLF5.JPG";
import SDLF6 from "./SDLF/SDLF6.JPG";
import SDLF7 from "./SDLF/SDLF7.JPG";

const CurrentWorks = () => {
  return (
    <div className="cw-container">
      <div className="cw-details">
        <div className="cw-section">
          <div className="h1-cw">
            <h1>Current Works</h1>
          </div>
          <div className="cw-text">
            <h2>Co-Director of Diversity, Equity, and Inclusion</h2>
            <p>
              In June 2023, Krystal Lacayo was formally confirmed to serve in
              the UNC Undergraduate Student Government within the Executive
              Branch as Co-Director of Diversity, Equity, and Inclusion in the
              Everett Administration. Krystal has shown dedication to creating
              an equitable institution at UNC from her work in South Africa and
              as a Sean Douglas Leadership Fellow at the Sonja Haynes Stone
              Center.
            </p>
            <p>
              It is her mission to not only reframe how DEI should be viewed,
              but also how it is implemented as she believes DEI is more of a
              philosophical approach that understands how history did not
              acknowledge and/or hindered social groups from achieving goals and
              living a prosperous life. Within this role, Krystal will work with
              student government members, cultural/affinity
              organizations/centers on campus, institutional leadership, and
              local/state government to ensure and facilitate a Carolina 4
              Everyone.
            </p>
            <p>
              Information regarding her work will be updated here. Please view
              the official UNC Undergraduate Student Government page for more
              information on the Everett Administration.
            </p>
          </div>
        </div>
        <div className="cw-section">
          <h2>Chair of Environmental and Climate Justice</h2>
          <div className="cw-text">
            <p>
              In August 2023, Krystal Lacayo was appointed to serve as the
              Environmental and Climate Justice Chair for the UNC Chapter of the
              NAACP. In this role, Krystal aims to educate her community on
              environmental concerns and advocate for new policies on issues
              including food insecurity, affordable housing and gentrification,
              zoning and development, and public transportation opportunities.
            </p>
            <p>Information regarding her work will be updated here.</p>
          </div>
        </div>
        <div className="cw-section">
          <h2>Sean Douglas Leadership Fellow</h2>
          <div className="cw-text">
            <h3>Fall 2023</h3>
            <p>
              In the fall of 2023, Krystal will continue her fellowship with the
              Stone Center and aims to focus on community land trusts and
              sustainability projects that affect the immediate communities of
              Carrboro and Durham. More info will be provided as she completes
              her project.
            </p>
            <h3>Spring 2023</h3>
            <p>
              In January of 2023, Krystal Lacayo received the opportunity to
              engage with community members as a Sean Douglas Leadership Fellow
              at the Sonja Haynes Stone Center at UNC. Within this role, Krystal
              was able to host her first seminar entitled “UNC Black Leadership
              Seminar”. It was her mission to provide Black college students
              with various perspectives regarding community building through
              dialogue, connecting with Black professionals, and fellowship so
              that they may intentionally build and sustain their communities
            </p>
            <p>
              She held the seminar with panelists that included three professors
              in various fields including business, social development and
              performance arts, and environmental justice, a panelist in the
              healthcare profession and a student development mentor from the
              UNC school of government. Krystal states in her recall of the
              seminar, “Their diverse and unique takes on what community is and
              what it will and should look like was incredibly important and
              beneficial to the audience and myself. We rounded the conversation
              to the point that “community” is safety, joy, health, and
              self/communal expression. To keep a “community” going, we must
              hold ourselves and one another accountable, create space for one
              another, and be intentional about our work for people who make up
              [the] community.” The seminar saw around 50 attendees who
              participated in the fellowship section of the seminar with the
              panelist.
            </p>
            {/* Carousel */}
            <div className="carousel-container-cw">
              <Carousel
                autoPlay
                interval={3000}
                infiniteLoop
                useKeyboardArrows
                dynamicHeight
                statusFormatter={(current, total) => `${current} of ${total}`}
              >
                <div>
                  <img src={SDLF1} alt="" />
                </div>
                <div>
                  <img src={SDLF2} alt="" />
                </div>
                <div>
                  <img src={SDLF3} alt="" />
                </div>
                <div>
                  <img src={SDLF4} alt="" />
                </div>
                <div>
                  <img src={SDLF5} alt="" />
                </div>
                <div>
                  <img src={SDLF6} alt="" />
                </div>
                <div>
                  <img src={SDLF7} alt="" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="cw-section">
          <h2>Founder of HBUNC/Mo' Control Media Group</h2>
          <div className="cw-text">
            <h3>Coming Soon...</h3>
            <div className="center">
              <a
                href="https://www.instagram.com/hbunc_/"
                className="linkedin-button"
              >
                <FontAwesomeIcon icon={faInstagram} className="linkedin-icon" />
                <u>Follow on Instagram</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentWorks;
