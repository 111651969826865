import React from "react";

const Projects = () => {
  return (
    <div className="ap-container">
      <div className="ap=details">
        <div className="ap-section">
          <div className="h1-ap">
            <h1>Academic Projects</h1>
          </div>
          <div className="ap-text">
            <h3>
              Spatial Racism in Mecklenburg, NC | Geography 370: Intro into GIS
            </h3>
            <p>
              In Spring 2023, Krystal worked with classmates to create a map
              uncovering racial discrimination found in Charlotte, NC while
              taking the Intro into GIS class. Here, her team uncovered spatial
              racism within the city, increasing the need for more affordable
              housing within certain racial and economic demographics.{" "}
              <a
                href="https://storymaps.arcgis.com/stories/ad7effe907394748b58aaadc78c43ddc"
                target="_blank"
                rel="noopener noreferrer"
              >
                Use this link to access their ArcGIS Story Map.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
